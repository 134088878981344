import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loader2 } from "lucide-react";
import DataTable from "react-data-table-component";
import { boolean } from "webidl-conversions";

const Home = () => {
  const ruleStyle = {
    color: "#eb8876",
    fontSize: "28px",
  };

  const ruleTypeStyle = {
    lineHeight: "1.5",
    fontSize: "28px",
  };

  const hrStyling = {
    width: "60%",
    color: "#d1c5b2",
    backgroundColor: "#d1c5b2",
    border: "none",
    height: "1px",
  };

  const submitStyle = {
    lineHeight: "1.5",
    fontSize: "28px",
    color: "black",
    textDecoration: "none",
    color: "#d1c5b2",
    transition: "color 0.3s ease", // Add transition for smooth color change
  };

  const submitHoverStyle = {
    ...submitStyle,
    color: "#eb8876", // Change color on hover
  };

  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const fetchRules = async () => {
      try {
        const response = await axios.get("/api/rules");
        console.log(response);
        setRules(response.data);
      } catch (error) {
        console.error("Error fetching rules:", error);
      } finally {
        setLoading(false);
        setTimeout(() => setFadeIn(true), 50);
      }
    };

    fetchRules();
  }, []);

  const fadeInStyle = {
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
  };

  const visibleStyle = {
    opacity: 1,
    transition: "opacity 0.5s ease-in-out",
  };
  const spinnerStyle = {
    border: "4px solid #f3f3f3",
    borderTop: "4px solid #3498db",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  if (loading) {
    return (
      <div
        className="flex items-center justify-center h-screen"
        style={fadeInStyle}
      >
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
        <div style={spinnerStyle}></div>
      </div>
    );
  }

  // if (!rules.length) {
  //   return ;
  // }

  return (
    <div
      className="container"
      style={{ ...(fadeIn ? visibleStyle : fadeInStyle), paddingTop: "10px" }}
    >
      <div style={{ textAlign: "center" }}>
        <div>
          {rules.length == 0 ? (
            <p style={{ padding: "10%", fontSize: "110%" }}>
              The current challenge's rules aren't available at the moment.
              Please try again later.
            </p>
          ) : 
          <div>
            <h2
          className="bebas-neue-regular"
          style={{ fontSize: 36, marginBottom: 20 }}
        >
          Rules for{" "}
          <span style={{ color: "#eb8876", cursor: "pointer" }}>
            <a
              target="_blank"
              href="https://www.meetup.com/short-cuts/"
              style={{ textDecoration: "none", color: "#eb8876" }}
            >
              {"Season " + rules.season + " Challenge " + rules.challenge}
            </a>
          </span>
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(auto, 150px))",
            gap: 20,
            justifyContent: "center",
          }}
        >
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>Form</h3>
            {/* add {rules.form here} */}
            <p>
              {/* Black and White */}
              {rules.form}
            </p>
          </div>
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>Wildcard</h3>
            {/* add {rules.wildcard here} */}
            <p>
              {/* Mixed Media */}
              {rules.wildcard}
            </p>
          </div>
        </div>
        <div>
          <h3 style={{ fontSize: 18, marginBottom: 10 }}>Deadline</h3>
          {/* add {rules.deadline here} */}
          <p>
            {/* 11:59pm on May 11th */}
            {"11:59pm on " + formatDate(rules.end_date_exc)}
          </p>
        </div>
        <hr style={{ height: "0px", border: "none" }}></hr>
        <span className="bebas-neue-regular" style={submitStyle}>
          <a
            href="/submit"
            style={submitStyle}
            onMouseEnter={(e) =>
              (e.target.style.color = submitHoverStyle.color)
            }
            onMouseLeave={(e) => (e.target.style.color = submitStyle.color)}
          >
            SUBMIT YOUR FILM HERE
          </a>
        </span>
          </div>}
        </div>
        
      </div>

      <hr style={hrStyling}></hr>

      <div
        style={{
          position: "relative",
          width: "100vw",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <img
          src="/vino.jpg"
          alt="Description of the image"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <div>
        <h1 className="bebas-neue-regular">About Us</h1>
        <p style={{ color: "white", lineHeight: "1.5" }}>
          Short Cuts is a group of independent filmmakers challenging themselves
          to create short films every ~3 weeks following a number of randomly
          drawn rules. Each meeting, filmmakers have the opportunity to show off
          their films and watch others, as well as draw the rules for the next
          film challenge. This group welcomes any experience level, from those
          who have never touched a camera, written a script, or acted in a film
          to seasoned veterans looking for a challenge.
        </p>
      </div>
      <hr style={hrStyling}></hr>
      <div style={{ textAlign: "center" }}>
        <h1 className="bebas-neue-regular" style={{ marginBottom: 20 }}>
          Our Values
        </h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 20,
          }}
        >
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>
              Community of Creatives
            </h3>
            <p>
              We believe in the power of collaboration and the strength of a
              creative community. Our goal is to bring together talented
              individuals who can support and uplift each other in their
              artistic pursuits.
            </p>
          </div>
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>
              Inclusion and Respect
            </h3>
            <p>
              Our filmmaking community values inclusion and respect for all
              members, regardless of their background or experience. We strive
              to create a welcoming environment where everyone feels valued and
              respected. Discrimination of any type will not be tolerated.
            </p>
          </div>
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>
              Learning Environment
            </h3>
            <p>
              We believe that everyone has something valuable to contribute and
              should be treated with equal respect and consideration. Everyone,
              regardless of socioeconomic status, race, religion, and so on, has
              the potential to create something beautiful.
            </p>
          </div>
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>
              Collaboration and Experimentation
            </h3>
            <p>
              Our community provides a space where members can come together to
              share ideas, try new approaches, and push the boundaries of what's
              possible. We tackle the unknown through collaboration and in turn
              strive to try new things for every project.
            </p>
          </div>
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>
              Everyone is Equal
            </h3>
            <p>
              We believe that everyone has something valuable to contribute and
              should be treated with equal respect and consideration. Everyone,
              regardless of socioeconomic status, race, religion, and so on, has
              the potential to create something beautiful.
            </p>
          </div>
          <div>
            <h3 style={{ fontSize: 18, marginBottom: 10 }}>
              Perfection through Practice
            </h3>
            <p>
              We believe that the key to success in filmmaking is through
              practice and perseverance. We strive for excellence through
              getting our reps in and never stop learning and improving.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export default Home;
