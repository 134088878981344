import { Link } from "react-router-dom";

const NavBar = () => {
    return ( 
        <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <Link to="/submit">Submission Form</Link>
              </li>

              {/* <li>
                <Link to="/submissions">Submissions List</Link>
              </li> */}
            </ul>
        </nav>
    );
};
 
export default NavBar;