import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";

// const data = [
//   {
//     id: 1,
//     title: "Beetlejuice",
//     year: "1988",
//   },
//   {
//     id: 2,
//     title: "Ghostbusters",
//     year: "1984",
//   },
// ];

const SubmissionList = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get("/api/submissionsform");
        setSubmissions(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching submissions:", error);
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const columns = [
    {
      name: "Directors",
      selector: (row) => row.directors,
      sortable: true,
      cell: (row) => row.directors.join(", "),
    },
    {
      name: "Film Title",
      selector: (row) => row.filmTitle,
      sortable: true,
    },
    {
      name: "Video Link",
      selector: (row) => row.videoLink,
      sortable: true,
    },
    {
      name: "Submission Time",
      selector: (row) => row.timestamp,
      sortable: true,
    },
    {
      name: "Debut",
      selector: (row) => (row.isFirstTimeFilmmaker ? "Yes" : "No"),
      sortable: true,
    },
  ];
  return (
    <div>
      {loading ? (
        <p>loading submissions</p>
      ) : (
        <DataTable
          title="Submissions"
          columns={columns}
          data={submissions}
          pagination
          highlightOnHover
          striped
          dense
          style={{ padding: "0px" }}
        />
      )}
    </div>
  );
};

export default SubmissionList;
