import React from "react";
import SubmissionList from "../components/SubmissionList";

const Submissions = () => {
  return (
    <div className="container" style={{ padding: "0px" }}>
      <h1>Submissions List</h1>
      <SubmissionList />
    </div>
  );
};

export default Submissions;
