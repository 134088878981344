import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import Submissions from "./pages/Submissions";
import NavBar from "./components/NavBar.js";
import SocialsBar from "./components/SocialsBar";
import SubmissionFormPage from "./pages/SubmissionFormPage";

const hrStyling = {
  width: "80%",
  color: "#d1c5b2",
  backgroundColor: "#d1c5b2",
  border: "none",
  height: "1px",
};

const App = () => {
  return (
    <Router>
      <div className="container">
        <div>
          <h1 className="title">Short Cuts NYC</h1>
        </div>
        <NavBar />
        <SocialsBar />
        <hr style={hrStyling}></hr>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/submissions" element={<Submissions />} />
          <Route path="/submit" element={<SubmissionFormPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

/**
 * 
 * to add:

-Separate field for a downloadable link
-A challenge code/label added to submission schema
-Require some fields to be filled (film name, director, link)
-make submissions list page only available to me and caleb (admins)


time stamps eastern time

 -First Time Filmmaker Checkbox d
-Submission timestamp added to submission schema. d
-format the data so that it's viewable through a datatable d
 * 
 */
