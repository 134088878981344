const SocialsBar = () => {
  const socialStyling = {
    width: "24px",
    height: "24px",
  };

  return (
    <div>
      <span style={{ marginRight: "10px" }}>
        <a
          href="https://www.meetup.com/short-cuts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/meetup.svg" alt="Meetup" style={socialStyling} />
        </a>
      </span>
      <span style={{ marginRight: "10px" }}>
        <a
          href="https://discord.gg/b9KRsFd2bQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/discord.svg" alt="Discord" style={socialStyling} />
        </a>
      </span>
      <span>
        <a
          href="https://www.instagram.com/shortcutsny/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/instagram.svg" alt="Instagram" style={socialStyling} />
        </a>
      </span>
    </div>
  );
};

export default SocialsBar;
