import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "../reactSelectStyles.css";
import CreatableSelect from "react-select/creatable";

const SubmissionForm = () => {
  const [name, setName] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [filmTitle, setFilmTitle] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [emailOptions, setEmailOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [borough, setBorough] = useState("");
  const [directors, setDirectors] = useState([]);
  const [editors, setEditors] = useState([]);
  const [writers, setWriters] = useState([]);
  const [dps, setDPs] = useState([]);
  const [producers, setProducers] = useState([]);
  const [actors, setActors] = useState([]);
  const [executiveProducers, setExecutiveProducers] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [present, setPresent] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [WildCardInfo, setWildCardInfo] = useState("");
  const [FormRuleInfo, setFormRuleInfo] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const [isFirstTimeFilmmaker, setIsFirstTimeFilmmaker] = useState(false);

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      marginBottom: "20px",
      border: "none",

      backgroundColor: "#d1c5b2",
      color: "#2c434d",
      boxSizing: "content-box",
      padding: "10px",
    }),
    control: (provided) => ({
      ...provided,
      width: "100%",
      marginBottom: "10px",
      border: "none",
      padding: "0px",
      backgroundColor: "#d1c5b2",
      color: "#2c434d",
      boxSizing: "content-box",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      width: "98%",

      border: "none",
      boxSizing: "content-box",
      backgroundColor: "#d1c5b2",
      color: "#2c434d",
      cursor: "pointer",
    }),
  };
  useEffect(() => {
    const fetchNameOptions = async () => {
      try {
        const response = await axios.get("/api/submissions");
        const submissions = response.data;
        const nameSet = new Set(
          submissions.map((submission) => submission.name)
        );
        const options = Array.from(nameSet).map((name) => ({
          value: name,
          label: name,
        }));
        setNameOptions(options);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching email options:", error);
        setLoading(false);
      }
    };

    fetchNameOptions();
  }, []);

  const createOption = (label) => ({
    label,
    value: label, //label.toLowerCase().replace(/\W/g, ""),
  });
  const handleSelectCreate = async (inputValue) => {
    const timestamp = new Date().toISOString();
    setLoading(true);
    try {
      const newEditor = createOption(inputValue);
      //const newEditor = inputValue;
      console.log(newEditor);
      //setNameOptions((prev) => [...prev, newEditor]);
      await axios.post("/api/submissions", {
        name: newEditor.label,
        timestamp: timestamp,
      });
      setLoading(false);
      setNameOptions((prev) => [...prev, newEditor]);
      //setEditors((prev) => [...prev, newEditor]); we don't know which field is doing this
    } catch (error) {
      console.error("Error adding new entry to database:", error);
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionStatus(null);
    try {
      const timestamp = new Date().toISOString();
      await axios.post("/api/submissionsform", {
        name,
        //emails: selectedEmails.map((option) => option.value),
        filmTitle,
        borough,
        directors: directors.map((option) => option.value),
        editors: editors.map((option) => option.value),
        writers: writers.map((option) => option.value),
        dps: dps.map((option) => option.value),
        producers: producers.map((option) => option.value),
        actors: actors.map((option) => option.value),
        executiveProducers: executiveProducers.map((option) => option.value),
        videoLink,
        present,
        additionalInfo,
        WildCardInfo,
        FormRuleInfo,
        isFirstTimeFilmmaker,
        timestamp,
      });
      setName("");
      setSelectedEmails([]);
      setFilmTitle("");
      setDirectorName("");
      setBorough("");
      setDirectors([]);
      setEditors([]);
      setWriters([]);
      setDPs([]);
      setProducers([]);
      setActors([]);
      setExecutiveProducers([]);
      setVideoLink("");
      setPresent("");
      setAdditionalInfo("");
      setWildCardInfo("");
      setFormRuleInfo("");

      setSubmissionStatus("success");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("error");
    }

    setIsSubmitting(false);
  };
  const textbox = {
    padding: "2px",
    color: "#eb8876",
  };
  const nonSelect = {
    padding: "2px",
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name" className="form-label" style={textbox}>
          What is the Title of the Film?
        </label>
        <input
          type="text"
          placeholder="Film Title"
          value={filmTitle}
          onChange={(e) => setFilmTitle(e.target.value)}
        />
      </div>
      {loading ? (
        <p>Loading names...</p>
      ) : (
        <div>
          <label className="form-label" style={textbox}>
            Please list the Director(s) of this film.
          </label>

          <CreatableSelect
            id="directors"
            isClearable
            isMulti
            onChange={setDirectors}
            onCreateOption={handleSelectCreate}
            options={nameOptions}
            value={directors}
            styles={selectStyles}
            menuPortalTarget={document.getElementById("select-menu-portal")}
          />
        </div>
      )}

      <div style={{ paddingBottom: "10px" }}>
        <label>
          <label className="form-label" style={textbox}>
            First-time filmmaker?
          </label>
          <input
            type="checkbox"
            checked={isFirstTimeFilmmaker}
            onChange={(e) => setIsFirstTimeFilmmaker(e.target.checked)}
          />
        </label>
        <span>
          This is the first film that I have ever directed in my life.
        </span>
      </div>

      {/* <input
        type="text"
        placeholder="Director Name"
        value={directorName}
        onChange={(e) => setDirectorName(e.target.value)}
      /> */}
      <div>
        <label className="form-label" style={textbox}>
          What borough was the film primary shot in?
        </label>
        <select
          value={borough}
          onChange={(e) => setBorough(e.target.value)}
          defaultValue="" // Set the default value to an empty string
          placeholder="Select a borough" // Use placeholder attribute
        >
          <option value="" disabled hidden>
            Select a Borough
          </option>
          <option value="Manhattan">Manhattan</option>
          <option value="Brooklyn">Brooklyn</option>
          <option value="Queens">Queens</option>
          <option value="The Bronx">The Bronx</option>
          <option value="Staten Island">Staten Island</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div>
        <label className="form-label" style={textbox}>
          Please list the Writers(s) of this film.
        </label>

        <CreatableSelect
          id="writers"
          isClearable
          isMulti
          onChange={setWriters}
          onCreateOption={handleSelectCreate}
          options={nameOptions}
          value={writers}
          styles={selectStyles}
          menuPortalTarget={document.getElementById("select-menu-portal")}
        />
      </div>
      <div>
        <label className="form-label" style={textbox}>
          Please list the Editor(s) of this films.
        </label>

        <CreatableSelect
          id="editors"
          isClearable
          isMulti
          onChange={setEditors}
          onCreateOption={handleSelectCreate}
          options={nameOptions}
          value={editors}
          styles={selectStyles}
          menuPortalTarget={document.getElementById("select-menu-portal")}
        />
      </div>

      <div>
        <label className="form-label" style={textbox}>
          Please list the Cinematographer(s) of this film.
        </label>

        <CreatableSelect
          id="dps"
          isClearable
          isMulti
          onChange={setDPs}
          onCreateOption={handleSelectCreate}
          options={nameOptions}
          value={dps}
          styles={selectStyles}
          menuPortalTarget={document.getElementById("select-menu-portal")}
        />
      </div>

      <div>
        <label className="form-label" style={textbox}>
          Please list the Producer(s) of this film.
          <div className="tooltip"><span className="icon">i</span><span class="tooltiptext">This is someone that helped on the film in any way that doesn't fall under the other categories.</span></div>
        </label>
        <CreatableSelect
          id="producers"
          isClearable
          isMulti
          onChange={setProducers}
          onCreateOption={handleSelectCreate}
          options={nameOptions}
          value={producers}
          styles={selectStyles}
          menuPortalTarget={document.getElementById("select-menu-portal")}
        />
      </div>

      <div>
        <label className="form-label" style={textbox}>
          Please list the Actor(s) in this film.
        </label>

        <CreatableSelect
          id="actors"
          isClearable
          isMulti
          onChange={setActors}
          onCreateOption={handleSelectCreate}
          options={nameOptions}
          value={actors}
          styles={selectStyles}
          menuPortalTarget={document.getElementById("select-menu-portal")}
        />
      </div>
      <div>
        <label className="form-label" style={textbox}>
          Please list the Executive Producer(s) of this film.
          <div className="tooltip"><span className="icon">i</span><span class="tooltiptext">This is someone other than the director that lent <i>any</i> gear or a location to the production.</span></div>
        </label>
        <CreatableSelect
          id="executiveproducers"
          isClearable
          isMulti
          onChange={setExecutiveProducers}
          onCreateOption={handleSelectCreate}
          options={nameOptions}
          value={executiveProducers}
          styles={selectStyles}
          menuPortalTarget={document.getElementById("select-menu-portal")}
        />
      </div>

      <div>
        <label className="form-label" style={textbox}>
          Please share the link for your film.
        </label>
        <input
          type="text"
          placeholder="Video Link"
          value={videoLink}
          onChange={(e) => setVideoLink(e.target.value)}
        />
      </div>
      <div>
        <label className="form-label" style={textbox}>
          Will you (the director) be present at the next screening? We will do our best to screen it if not, but the director must be present for it to be prioritized.{" "}
          <a
            target="_blank"
            href="https://www.meetup.com/short-cuts/"
            style={{ textDecoration: "none", color: "#e7b0a6" }}
          >
            RSVP here
          </a>
        </label>
        <select value={present} onChange={(e) => setPresent(e.target.value)}>
          <option value="">Will the director be present?</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div>
        <label className="form-label" style={textbox}>
          Where is the Form (rule #1) in your film? Provide description and/or
          timestamp.
        </label>
        <textarea
          id="FormRuleInfo"
          value={FormRuleInfo}
          onChange={(e) => setFormRuleInfo(e.target.value)}
          className="form-input"
          rows="3"
        ></textarea>
      </div>
      <div>
        <label className="form-label" style={textbox}>
          Where is the Wildcard (rule #2) in your film? Provide description
          and/or timestamp.
        </label>
        <textarea
          id="WildCardInfo"
          value={WildCardInfo}
          onChange={(e) => setWildCardInfo(e.target.value)}
          className="form-input"
          rows="3"
        ></textarea>
      </div>
      <div>
        <label className="form-label" style={textbox}>
          Do you have anything you'd like to add about your experience making
          this film, being a part of Short Cuts, or anything else you'd like us
          to know about your film?
        </label>
        <textarea
          id="additionalInfo"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          className="form-input"
          rows="5"
        ></textarea>
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        style={{ cursor: isSubmitting ? "wait" : "pointer" }}
      >
        {isSubmitting ? "Submitting..." : "Submit"}
      </button>

      {submissionStatus === "success" && (
        <div className="alert success">
          <span role="img" aria-label="Success">
            ✅
          </span>{" "}
          Submission successful!
        </div>
      )}
      {submissionStatus === "error" && (
        <div className="alert error">
          <span role="img" aria-label="Error">
            ❌
          </span>{" "}
          Error submitting the form. Please try again.
        </div>
      )}
    </form>
  );
};

export default SubmissionForm;
