import React from "react";
import SubmissionForm from "../components/SubmissionForm";

const SubmissionFormPage = () => {
  return (
    <div
      className="container"
      style={{ marginLeft: "10px", marginRight: "10px" }}
    >
      <h1>Submission Form</h1>
      <SubmissionForm />
    </div>
  );
};

export default SubmissionFormPage;
